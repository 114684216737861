import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOMServer from 'react-dom/server';
import { IconSize, palette } from '@liasincontrol/ui-basics';
import { Popup, ScrollView } from '@liasincontrol/ui-devextreme';
import '../../assets/dx.omnia-scheme.custom.css';
import { ButtonType } from 'devextreme/common';

export type ToolBarProps = {
    enabled: boolean;
    detached?: boolean;
    primary?: boolean;
    leftButtonText?: string;
    leftButtonIcon?: string;
    leftButtontype?: ButtonType | string;
    onLeftButtonClick?: () => void;
    leftButtonDisabled?: boolean;
    rightButtonText?: string;
    rightButtonIcon?: string;
    rightButtontype?: ButtonType | string;
    onRightButtonClick?: () => void;
    rightButtonDisabled?: boolean;
}

type Props = {
    id?: string,
    title?: string,
    titleIcon?: React.JSX.Element,
    toolbar?: ToolBarProps,
    width?: number | string,
    height?: number | string,
    children: React.ReactNode,
    customToolbarItems?: any[];
    leftContent?: React.ReactNode;
    className?: string;
}

export const LsModal: React.FC<Props> = (props) => {
    const toolbarItems = [];

    if (props.title) {
        toolbarItems.push(
            {
                template: () => {
                    const container = document.createElement('div');
                    const root = createRoot(container);
                    root.render(props.titleIcon);
                    return container;
                },
                toolbar: 'top',
                location: 'before'
            },
            {
                text: props.title,
                toolbar: 'top',
                location: "before"
            });
    }

    if (props.toolbar?.enabled) {
        const extraOptions = {
            stylingMode: props.toolbar?.primary ? 'text' : 'contained',
            type: props.toolbar?.primary ? 'normal' : 'default',
            elementAttr: {
                'class': props.toolbar?.primary ? ' dx-button-normal-inverted' : ''
            }
        };

        if (props.toolbar?.onLeftButtonClick) {
            toolbarItems.push({
                widget: 'dxButton',
                location: 'before',
                toolbar: 'bottom',
                options: {
                    text: props.toolbar?.leftButtonText || 'Sluiten',
                    onClick: props.toolbar?.onLeftButtonClick,
                    disabled: props.toolbar?.leftButtonDisabled,
                    ...extraOptions,
                    stylingMode: 'text',
                    icon: props.toolbar?.leftButtonIcon,
                    type: props.toolbar?.leftButtontype,
                }
            });
        }

        if (props.customToolbarItems) {
            props.customToolbarItems.forEach((item) => {
                if (item.options?.id) {
                    extraOptions.elementAttr['id'] = item.options?.id;
                }
                toolbarItems.push({
                    widget: 'dxButton',
                    location: item.location || 'center',
                    toolbar: 'bottom',
                    options: {
                        ...extraOptions,
                        ...item.options,
                        onClick: (e) => {
                            item.options.onClick?.(e); // Pass event `e` to the original handler
                        }
                    }
                });
            });
        }

        if (props.toolbar?.onRightButtonClick) {
            toolbarItems.push({
                widget: 'dxButton',
                location: 'after',
                toolbar: 'bottom',
                options: {
                    text: props.toolbar?.rightButtonText || 'Opslaan',
                    onClick: props.toolbar?.onRightButtonClick,
                    disabled: props.toolbar?.rightButtonDisabled,
                    ...extraOptions,
                    icon: props.toolbar?.rightButtonIcon,
                    type: props.toolbar?.rightButtontype,
                }
            });
        }
    }

    if (!props.toolbar?.enabled) {
        if (props.toolbar?.onLeftButtonClick) {
            toolbarItems.push({
                widget: 'dxButton',
                location: 'after',
                toolbar: 'top',
                options: {
                    text: props.toolbar?.leftButtonText || 'Sluiten',
                    onClick: props.toolbar?.onLeftButtonClick,
                    disabled: props.toolbar?.leftButtonDisabled,
                    type: props.toolbar?.leftButtontype ?? props.toolbar?.primary ? 'normal' : 'default',
                    stylingMode: 'outlined',
                    icon: props.toolbar?.leftButtonIcon,
                }
            });
        }
    }
    let popupClassName = 'ls-popup';
    if (props.toolbar?.detached) popupClassName += ' ls-popup-fullscreen';
    if (props.title || props.titleIcon) popupClassName += ' ls-popup-with-title';
    if (props.toolbar?.enabled) popupClassName += ' ls-popup-with-toolbar';
    if (props.toolbar?.primary) popupClassName += ' ls-popup-primary';
    if (props.className) popupClassName += ' ' + props.className;

    return (<Popup
        wrapperAttr={{
            class: popupClassName,
            id: props.id || 'ls-popup',
        }}
        enableBodyScroll={false}
        minWidth={400}
        minHeight={200}
        width={props.width || (props.toolbar?.detached ? window.innerWidth - 80 : '600')}
        height={props.height || (props.toolbar?.detached ? window.innerHeight - 20 : 'auto')}
        maxWidth={props.toolbar?.detached ? window.innerWidth : window.innerWidth - 80}
        maxHeight={props.toolbar?.detached ? window.innerHeight : window.innerHeight - 80}
        showTitle={!!props.title}
        fullScreen={props.toolbar?.detached}
        toolbarItems={toolbarItems}
        dragEnabled={false}
        // Prevent interference with the sticky toolbar rendered to body (outside). Possible solution: handle the target element appropriately
        hideOnOutsideClick={!props.toolbar?.detached}
        visible={true}
        onHiding={props.toolbar?.onLeftButtonClick}
        showCloseButton={false}
        shading={true}
        shadingColor='rgba(0, 0, 0, 0.5)'
        animation={undefined}
    >
        {!props.leftContent
            ? props.children
            : <ScrollView
                direction='both'
                useNative={true}
                scrollByThumb={true}
                height='100%'
            >
                <div
                    className={`popup-content ${!props.toolbar?.detached ? 'p-050' : ''}`}
                    style={{ height: `calc(${props.height ? `${props.height}px` : '100%'} - 5rem)` }}
                >
                    {props.children}
                </div>
            </ScrollView>
        }
        {props.leftContent}
    </Popup>
    );
};

export const getMuiIconAsSvgString = (IconComponent, iconSize: IconSize = IconSize.medium, color: string = palette.primary2) => {
    return ReactDOMServer.renderToStaticMarkup(<IconComponent style={{ fill: color, width: iconSize, height: iconSize }} />);
};
